import axios from 'axios';

const CartService = {
    async fetchCheckout() {
        return axios.get('/cart/api/index');
    },
    async fetchOils() {
        return axios.get('/cart/api/oils');
    },
    async add(buyable, id, quantity) {
        return axios.post('/cart/api/add', {
            buyable,
            id,
            quantity
        });
    },
    async remove(key) {
        return axios.post('/cart/api/remove', {
            key
        });
    },
    async increase(key, amount) {
        return axios.post('/cart/api/increase', {
            key,
            amount
        });
    },
    async decrease(key, amount) {
        return axios.post('/cart/api/decrease', {
            key,
            amount
        });
    },
    async applyPromo(code) {
        return axios.post('/cart/api/apply_promo', {
            code
        });
    },
    async getIsAdmin() {
        return axios.post('/cart/api/get_is_admin');
    }
};

export default CartService;